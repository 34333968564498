.site-footer {

    &__top {
        background-color: $white;
        padding: 15px 0;

        .row {
            justify-content: space-between !important;
            align-items: center !important;

            .col-1 {
                text-align: center;
                margin-bottom: 15px;

                @include lg {
                    text-align: left;
                    margin-bottom: 0;
                }
            }

            .col-2 {
                text-align: center;

                @include lg {
                    text-align: right;
                }
            }
        }

        .footer-logo {
            display: inline-block;
            text-decoration: none;
            font-weight: 700;
            font-size: 26px;
            color: $primary;
            text-transform: uppercase;
            border: 4px solid $primary;
            max-width: 220px;
            padding: 10px 20px;


            @include lg {
                font-size: 28px;
            }
        }

        .social-list {
            text-align: center;
            display: inline-block;

            @include lg {
                text-align: right;
            }

            h4 {
                margin-bottom: 10px;
            }

            ul {
                justify-content: center;
                padding-left: 0;

                // @include lg {
                //     justify-content: flex-end;
                // }
            }
        }
    }

    &__bottom {
        background-color: $primary;
        padding: 15px;
        text-align: center;

        @include lg {
            padding: 20px;
        }

        .copyright {
            color: $white;
            line-height: 1.5;
        }

        .link-text {
            text-decoration: none;
            color: $white;
            transition: all 0.3s;
            border-bottom: 1px solid $white;
        }

    }
}