@import "../sass/breakpoints";
@import "../sass/variables";

.profile {
    &-block {
        padding-top: 120px;

        @include mdDown {
            padding-bottom: 50px;
        }

        .row {
            align-items: center !important;
            flex-direction: row-reverse !important;

            .right {
                text-align: center;
            }
        }
    }

    &-info {
        h1 {
            .t-text {
                display: block;
                color: $primary;
            }

            .c-text {
                color: $primary;

            }

            .b-text {
                display: block;
                font-size: 28px;

                @include lg {
                    font-size: 30px;
                }

                @include xl {
                    font-size: 35px;
                }
            }
        }

        .btn-wrap {
            display: flex;
            align-items: center;

            .btn {
                -webkit-animation: action 1s infinite alternate;
                animation: action 1s infinite alternate;
            }
        }
    }

    &-media {
        // display: inline-block;
        position: relative;
        max-width: 600px;
        overflow: hidden;
        margin: 0 auto;

        @include mdDown {
            margin-bottom: 30px;
            border-bottom: 4px solid $gray-200;
        }

        &::before {
            position: absolute;
            content: '';
            top: 20%;
            right: 2%;
            width: 60px;
            height: 60px;
            background: $primary;
            border-radius: 50%;
            z-index: -1;
            box-shadow: inset 0px -5px 10px 0px rgba(0, 255, 255, 0.7), rgba(0, 0, 0, 0.25) 0px 0px 20px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
            transition: all 0.4s;

            @include lg {
                width: 100px;
                height: 100px;
                right: 5%;
            }

        }

        &::after {
            position: absolute;
            content: '';
            bottom: 10%;
            left: 3%;
            width: 40px;
            height: 40px;
            background: $primary;
            border-radius: 50%;
            z-index: -1;
            box-shadow: inset 0px -5px 10px 0px rgba(0, 255, 255, 0.7), rgba(0, 0, 0, 0.25) 0px 0px 20px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
            transition: all 0.4s;

            @include lg {
                width: 70px;
                height: 70px;
            }

        }


        img {
            max-width: 180px;

            @include md {
                max-width: 220px;
            }

            @include lg {
                max-width: 280px;
            }

            @include xl {
                max-width: 310px;
            }
        }
    }
}

.about {
    &-row {
        align-items: center !important;
    }

    &-media {
        background-color: $primary;
        border-radius: 8px;
        max-width: 320px;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 10px 10px 0 10px;

        @include mdDown {
            margin: 0 auto;
        }

        @include lg {
            max-width: 420px;
        }

        img {
            width: 100%;
            max-width: 230px;

            @include lg {
                max-width: 330px;
            }


        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($primary, 0.3);
            transform: rotate(5deg);
            z-index: -1;
            border-radius: 6px;

            @include lg {
                width: 100%;
            }
        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: -moz-linear-gradient(botom, rgba(8, 145, 178, 1) 0%, rgba(8, 145, 178, 0) 20%);
            background: -webkit-linear-gradient(botom, rgba(8, 145, 178, 1) 0%, rgba(8, 145, 178, 0) 20%);
            background: linear-gradient(to top, rgba(8, 145, 178, 1) 0%, rgba(8, 145, 178, 0) 20%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0891b2', endColorstr='#000891b2', GradientType=0);
            z-index: 1;
            border-radius: 0 0 8px 8px;
        }

        &__wrap {
            padding: 40px;

            @include lg {
                padding: 65px;
            }
        }
    }

    &-content {
        .experience-title {
            margin-top: 20px;

            .h-text {
                font-size: 32px;
                font-weight: 700;
                position: relative;
                z-index: 1;

                @include lg {
                    font-size: 46px;

                }

                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: rgba($primary, 0.8);
                    z-index: -1;

                }
            }

            .s-text {
                font-size: 26px;

                @include lg {
                    font-size: 32px;

                }

                strong {
                    color: $primary;
                }
            }
        }

        .btn-wrap {
            a {
                margin-right: 8px;

                @include lg {
                    margin-right: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.skills-block {
    .skill-list {
        padding-top: 20px;

        ul {
            padding-left: 0;

            max-width: 1000px;
            margin: 0 auto;

            .row {
                justify-content: space-between !important;

                .col {
                    margin-bottom: 12px;

                    @include lg {
                        margin-bottom: 20px;
                    }
                }
            }

            li {
                background-color: $primary;
                list-style-type: none;
                padding: 10px;
                border-radius: 6px;
                color: $white;
                position: relative;
                padding-left: 25px;
                max-width: 415px;
                margin: 0 auto;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;


                @include lg {
                    font-size: 20px;
                    padding-left: 30px;
                }


                &:before {
                    position: absolute;
                    content: '';
                    top: 16px;
                    left: 10px;
                    width: 8px;
                    height: 8px;
                    background: $white;
                    border-radius: 50%;

                    @include lg {
                        top: 24px;
                        left: 10px;
                    }
                }
            }
        }
    }
}


.services {
    &-block {
        .row {
            padding-top: 35px;
            justify-content: center !important;

            @include lg {
                padding-top: 45px;
            }

            .col {
                margin-bottom: 25px;

                @include lg {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.hiring {
    &-block {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
        background-size: 100% !important;

        @include lg {
            min-height: 350px;
        }

        @include xl {
            min-height: 450px;
        }
    }

    &-content {
        text-align: center;
        padding: 20px;

        h2 {
            color: $white;
            text-shadow: 1px 1px 2px $black;

        }
    }
}

.testimonial {

    &-slider {
        @include mdDown {
            padding-bottom: 50px;
        }

        @include lg {
            padding: 0 55px;
            padding-bottom: 30px;
        }


        .slick {

            &-list {
                transition: all 0.5s;
            }

            &-prev,
            &-next {
                z-index: 1;
                width: 40px;
                height: 40px;
                background-color: $primary;
                border-radius: 50%;
                border: 2px solid $white;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                @include lg {
                    width: 50px;
                    height: 50px;

                }

                @include mdDown {
                    top: auto;
                    bottom: 0;
                }

                &:hover,
                &:focus {
                    background-color: $primary;
                }

                &:before {
                    content: '';

                }

                &::after {
                    position: absolute;
                    content: '';
                    background: url('../images/icons/right-arrow.svg') no-repeat;
                    background-size: contain;
                    background-position: center;
                    z-index: 1;
                    width: 50%;
                    height: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &-prev {
                left: 33%;

                @include md {
                    left: 36%;
                }

                @include lg {
                    left: 0;
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(180deg);

                }

            }

            &-next {
                right: 33%;

                @include md {
                    right: 36%;
                }

                @include lg {
                    right: 0;
                }
            }

            &-dots {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);

                li {
                    margin: 0 3px;


                    button {
                        background-color: rgba($gray-900, 0.5);
                        border-radius: 50%;
                        width: 6px;
                        height: 6px;
                        transition: all 0.3s;

                        &:before {
                            display: none;
                        }
                    }

                    &.slick-active {
                        button {
                            transition: all 0.3s;
                            background-color: $primary;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }

}


.portfolio {
    &-content {
        // padding-top: 40px;

        .row {
            justify-content: center !important;

            .col {
                margin-bottom: 30px;
            }
        }

        .filter-nav {
            margin-bottom: 40px;
        }
    }
}

.resume {
    &-wrap {
        text-align: center;

        .react-tabs {
            &__tab-list {
                margin-bottom: 30px;
                border-bottom: none;
                text-align: center;

                @include lg {
                    margin-bottom: 40px;
                }

                @include xl {
                    margin-bottom: 50px;
                }
            }

            &__tab {
                font-weight: 600;
                color: $gray-800;
                border: none;
                border-radius: 3px;
                background-color: $gray-300;
                box-shadow: inset 0px -5px 10px 0px rgba(163, 161, 161, 0.5);
                position: initial;
                bottom: 0;
                // margin-right: 15px;
                padding: 12px 22px;
                margin-bottom: 10px;

                @include sm {
                    margin-bottom: 0;
                }


                @include lg {
                    font-size: 18px;
                    padding: 15px 45px;

                }

                @include xl {
                    font-size: 22px;
                }

                &:focus {
                    outline: none;
                }

                svg {
                    position: relative;
                    top: 2px;
                    margin-left: 7px;

                    @include lg {
                        top: 4px;

                    }
                }

                &--selected {
                    background-color: $primary;
                    color: $white;
                    box-shadow: inset 0px -5px 10px 0px rgba(0, 255, 255, 0.7);

                }
            }
        }
    }
}