@import "../../sass/breakpoints";
@import "../../sass/variables";

.filter-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    text-align: center;
    flex-wrap: wrap;

    li {
        list-style-type: none;
        font-weight: 800;
        margin: 5px 7px;
        padding-bottom: 5px;
        cursor: pointer;
        transition: all 0.4s;
        position: relative;
        font-size: 15px;

        @include lg {
            margin: 5px 12px;
            font-size: 18px;
        }

        &:first-child {
            margin-left: 0;
        }


        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &.active {
            color: $primary;

            &::before {
                width: 100%;
            }
        }

        &::before {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            height: 3px;
            width: 0;
            background: $primary;
            border-radius: 12px;
            transition: all 0.3s;

        }
    }
}