@import "../../sass/breakpoints";
@import "../../sass/variables";

.dash-wrap {
    display: flex;

    .left-content {
        background-color: $primary;
        width: 300px;

        .dash-logo {
            text-align: center;
            padding: 20px;

            a {
                display: inline-block;
                text-decoration: none;
                font-weight: 700;
                font-size: 26px;
                color: $white;
                text-transform: uppercase;
                border: 4px solid $white;
                max-width: 220px;
                padding: 10px 15px;


                @include lg {
                    font-size: 28px;
                    padding: 10px 20px;

                }
            }
        }

        .dash-menu {
            ul {
                padding-left: 0;
                list-style-type: none;
                text-align: center;

                li {
                    color: $white;
                    padding: 15px;
                    text-transform: capitalize;


                    &.active {
                        background-color: $white;
                        color: $primary;
                        border-right: 4px solid $primary;
                    }
                }
            }
        }
    }

    .right-content {
        width: 700px;

        .header-top {
            display: flex;
            justify-content: space-between;

            .icon {
                width: 40px;
                height: 30px;
                cursor: pointer;

                span {
                    display: block;
                    background-color: $primary;
                    width: 40px;
                    height: 4px;
                    margin-bottom: 6px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}