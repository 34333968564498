//error-page

.error {
    &-wrapper {
      min-height: 700px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &-content {
      text-align: center;
    }
  }