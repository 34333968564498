//custom padding
.padding-t {
    padding-top: 30px;

    @include md {
        padding-top: 40px;
    }

    @include lg {
        padding-top: 50px;
    }

    @include xl {
        padding-top: 60px;
    }
}

.padding-b {
    padding-bottom: 30px;

    @include md {
        padding-bottom: 40px;
    }

    @include lg {
        padding-bottom: 50px;
    }

    @include xl {
        padding-bottom: 60px;
    }
}

.padding-tb {
    padding-top: 15px;
    padding-bottom: 15px;

    @include md {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @include lg {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    @include xl {
        padding-top: 30px;
        padding-bottom: 30px;
    }

}

.bg-light-gray {
    background-color: $gray-100 !important;
}


.primary-overlay {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($primary, 0.85);
        z-index: -1;
    }
}

.dark-overlay {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.5);
        z-index: 11;
    }
}