@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes action {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes action {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}