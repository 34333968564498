@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-roboto: 'Roboto Slab', serif;

//colors

$sky-blue: #0891b2;
$dark-blue: #06223f;
$light-green: #09eb98;
$dark-magenta: #350B40;
$teal: #0ABEAD;
$purple: #8f07dd;
$red: #ea0808;
$yellow: #d7c209;
$blue: #081ddc;
$orange: #F57F17;
$light-pink: #d905d5;
$gray-100: #F3F2F3;
$gray-200: #E5E3E5;
$gray-300: #dee2e6;
$gray-400: #B7B6B7;
$gray-500: #9B9A9B;
$gray-600: #808080;
$gray-700: #666666;
$gray-800: #4F4E4F;
$gray-900: #343334;
$black: #000000;
$white: #ffffff;
$primary: $sky-blue;
$secondary: $red;