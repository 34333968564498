@import "../../sass/breakpoints";
@import "../../sass/variables";

.navbar {
    @include mdDown {
        position: fixed;
        top: 0;
        left: 0;
        // bottom: 0;
        height: 100vh;
        width: 240px;
        transform: translateX(0);
        background: rgb(30, 87, 153);
        background: -moz-linear-gradient(-45deg, rgba($primary, 1) 0%, rgb(59, 163, 202, 1) 100%);
        background: -webkit-linear-gradient(-45deg, rgba($primary, 1) 0%, rgba(59, 163, 202, 1) 100%);
        background: linear-gradient(-45deg, rgba($primary, 1) 0%, rgba(59, 163, 202, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=1);
        z-index: 999;
        transition: all 0.3s;
        padding: 52.8px 12px 12px 12px;
        box-shadow: inset -5px 0px 10px 0px rgba(0, 255, 255, 0.7);

        // box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    }

    &.active {
        @include mdDown {
            transform: translateX(-240px);

        }
    }

    &-nav {
        padding: 0;
        margin: 0;

        @include lg {
            display: flex;
            align-items: center;
            justify-content: space-between;

        }

        .nav-item {
            list-style-type: none;
            margin: 3px 0;
            text-align: center;

            @include mdDown {
                padding: 12px;
                // background-color: $primary;
            }

            @include lg {
                background-color: transparent;
                margin: 6px 2px;
                text-align: initial;
            }

            @include xl {
                margin: 6px 11px;

            }

            &:hover,
            &.active {

                .nav-link {
                    color: $white;

                    &::before {
                        width: 100%;
                    }
                }
            }


        }

        .nav-link {
            color: $white;
            font-weight: 600;
            font-size: 16px;
            text-decoration: none;
            padding-bottom: 5px;
            position: relative;
            transition: all 0.3s;
            // text-shadow: 1px 1px 2px $black;

            @include lg {
                font-size: 17px;
                padding: 7px;
            }

            @include xl {
                font-size: 18px;
                padding: 7px 3px;
            }

            &::before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                height: 3px;
                width: 0;
                background: $white;
                border-radius: 12px;
                transition: all 0.3s;

            }

        }
    }
}