@import "../../sass/breakpoints";
@import "../../sass/variables";


.block-title {
    margin-bottom: 22px;

    @include lg {
        margin-bottom: 30px;
    }

    h6 {
        display: inline-block;
        padding: 8px 12px;
        border-radius: 6px;
        font-size: 14px;
        text-transform: uppercase;
        color: $primary;
        line-height: 1.2;
        margin-bottom: 10px;
        background-color: rgba($primary, 0.18);
        letter-spacing: 2px;

        @include lg {
            font-size: 16px;
        }
    }

    h2 {
        display: block;
        // text-transform: capitalize;
        color: $gray-900;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1;
        line-height: 1.2;
        font-size: 22px;

        @include md {
            font-size: 26px;
        }
    
        @include lg {
            font-size: 32px;
        }
    }

}