@import "../../sass/breakpoints";
@import "../../sass/variables";

.btn {
    display: inline-block;
    background-color: $primary;
    padding: 14px 18px;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    border-color: transparent;
    outline: none;
    cursor: pointer;
    min-width: 145px;
    line-height: 1.2;
    border-radius: 35px;
    transition: all 0.4s;
    box-shadow: inset 0px -5px 10px 0px rgba(0, 255, 255, 0.7);



    @include lg {
        padding: 12px 25px;
        font-size: 18px;
        min-width: 155px;
    }

    @include xl {
        padding: 16px 30px;
        font-size: 20px;
        min-width: 170px;
    }

    &:hover {
        box-shadow: inset 0px 5px 10px 0px rgba(0, 255, 255, 0.7);

    }

    &--alt {
        background-color: $white;
        color: $primary;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            transform: translateY(-5px);
        }
    }

    &.btn-sm {
        @include lg {
            padding: 12px 25px;
        }
    }


    svg {
        fill: $white;
        position: relative;
        top: 3px;
        margin-left: 8px;
    }
}