@import "../../../sass/breakpoints";
@import "../../../sass/variables";

.resume-card {
    display: flex;
    // align-items: center;
    margin-left: -18px;
    margin-right: -18px;
    position: relative;
    max-width: 850px;
    margin: 0 auto;

    @include lg {
        padding-left: -25px;
        padding-right: -25px;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background: $primary;

        @include lg {
            width: 3px;

        }
    }

    &:after {
        position: absolute;
        content: '';
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid $white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background: $primary;

        @include lg {
            width: 16px;
            height: 16px;
        }
    }

    .time-period {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 18px;
        padding-right: 18px;
        text-align: right;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: right;

        @include lg {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 35px;
        }

        span {
            color: $primary;
            display: block;
            font-size: 15px;
            font-weight: 800;

            @include lg {
                font-size: 18px;
            }
        }
    }

    .content {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 18px;
        padding-right: 18px;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;

        @include lg {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 35px;

        }


        h4 {
            margin-bottom: 10px;
            line-height: 1;
            font-size: 16px;

            @include md {
                font-size: 18px;
            }

            @include lg {
                font-size: 22px;
            }

            @include xl {
                font-size: 24px;
            }
        }

        .org {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;

            @include lg {
                font-size: 18px;
            }
        }

        .per {
            display: block;
            color: $primary;
            font-size: 15px;

            @include lg {
                font-size: 18px;
            }
        }
    }

    &--alt {
        flex-direction: row-reverse;


        .time-period {
            text-align: left;
        }

        .content {
            text-align: right;
        }
    }
}