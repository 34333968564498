@import "../../sass/breakpoints";
@import "../../sass/variables";

.form {
    &-holder {
        padding-top: 20px;
    }

    max-width: 650px;
    margin: 0 auto;

    &-group {
        margin-bottom: 18px;

        @include lg {
            margin-bottom: 26px;
        }
    }

    &-control {
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        background-color: rgba($primary, 0.2);
        border-radius: 6px;
        padding: 10px 10px 10px 15px;

        @include lg {
            height: 45px;
            padding: 15px 15px 15px 18px;

        }

        @include xl {
            height: 55px;
        }


        &::placeholder {
            font-size: 14px;
            font-family: $font-roboto;

            @include lg {
                font-size: 16px;

            }
        }
    }

    textarea {
        height: 80px;
        resize: none;
        font-family: $font-roboto;

        @include lg {
            height: 95px;
        }

        @include xl {
            height: 130px;
        }
    }

    .btn-wrap {
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            @include lg {
                min-width: 140px;
            }

            svg {
                position: initial;
            }

            .spinner {
                -webkit-animation: loading 1s infinite steps(8);
                /* Safari */
                animation: loading 1s infinite steps(8);
            }
        }
    }
}