@import "./variables";

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
}

body {
    min-width: 320px;
    overflow-x: hidden;
    margin: 0 !important;
    font-family: $font-roboto;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: $gray-900;
    line-height: 1.5;
    // background: rgb(8,12,40);
    // background: -moz-linear-gradient(left,  rgba(8,12,40,1) 0%, rgba(90,96,168,1) 100%);
    // background: -webkit-linear-gradient(left,  rgba(8,12,40,1) 0%,rgba(90,96,168,1) 100%);
    // background: linear-gradient(to right,  rgba(8,12,40,1) 0%,rgba(90,96,168,1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#080c28', endColorstr='#5a60a8',GradientType=1 );
    

    @include xl {
        font-size: 18px;
        line-height: 1.7;
    }

}

a {
    transition: all .2s;

    &:focus {
        outline: none;
    }
}

img {
    max-width: 100%;
    height: auto;
    width: auto;
    vertical-align: top;
}

picture {
    display: block;
}

.text-center {
    text-align: center;
}

.bg-cover {
    position: relative;
    z-index: 0;

    &>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.stretched-link {
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0, 0, 0, 0);
    }
}