.site {
	&-wrapper {
		margin: 0 auto;
		display: flex;
		min-height: 100%;
		position: relative;
		align-items: stretch;
		flex-direction: column;
		min-width: 320px;
		overflow: hidden;
	}

	&-content {
		flex-grow: 1;

		@media screen\0 {
			flex-grow: 0;
		}
	}

	&-header,
	&-content,
	&-footer {
		flex-shrink: 0;
	}
}

.custom-container {
    margin: auto;
    padding: 0 20px;
  
    @include sm {
      max-width: 540px;
    }
  
    @include md {
      max-width: 720px;
    }
  
    @include lg {
      max-width: 960px;
	  padding: 0 40px;
    }
  
    @include xl {
      max-width: 1140px;
    }
  
    @include xxl {
      max-width: 1310px;
    }
  }