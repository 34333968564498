.btn-wrap {
    margin-top: 25px;

    @include lg {
        margin-top: 32px;
    }

    @include xl {
        margin-top: 36px;
    }
}

.social-list {
    ul {
        display: flex;

        li {
            list-style-type: none;
            // background-color: $white;
            line-height: 0;
            margin: 3px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            border-radius: 50%;
            transition: all 0.3s;

            @include lg {
                margin: 5px;
            }

            &:hover {
                transform: translateY(-5px);
            }


            a {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    fill: $white;
                }
            }

        }

        .linkedin-icon {
            background-color: #0072b1;
        }

        .github-icon {
            background-color: #171515;
        }

        .skype-icon {
            background-color: #00aff0;
        }
    }
}
