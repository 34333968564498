@import "../../sass/breakpoints";
@import "../../sass/variables";


.blockquote {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    padding: 30px 15px 25px 15px;

    p {
        font-weight: 400;
        color: $gray-900;
    }

    .author-media {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border: 2px solid $white;
        margin-top: 25px;
    }

    h3 {
        color: $primary;
        margin-top: 10px;

        span {
            display: block;
            font-size: 16px;
            color: $gray-600;
        }
    }
}