@import "../../../sass/breakpoints";
@import "../../../sass/variables";

.project-card {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 6px 0px rgba($primary, 0.3), 0px -5px 6px -1px rgba($primary, 0.3);
    cursor: pointer;
    transition: all 0.4s;
    height: 100%;

    &:hover {
        transform: translateY(-5px);
        box-shadow: rgba($primary, 0.5) 0px 10px 20px, rgba($primary, 0.53) 0px 6px 6px;
        // background-color: rgba($primary, 0.2);

        .project-card__media {
            img {
                transform: scale(1.05);
            }
        }

        .cta-wrap {
            span {
                color: $primary;
            }
        }

    }

    &__media {
        display: inline-block;
        width: 100%;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        padding: 25px 30px;
        background-color: rgba($primary, 0.2);


        // @include xl {
        //     min-height: 285px;
        // }

        img {
            transition: all 0.4s;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;


        }
    }

    &__content {
        padding: 18px 22px;

        @include lg {
            padding: 25px 30px;

        }

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .category {
                display: inline-block;
                padding: 8px 12px;
                border-radius: 4px;
                font-size: 14px;
                color: $primary;
                font-weight: 800;
                line-height: 1.2;
                background-color: rgba($primary, 0.2);
                letter-spacing: 2px;

                @include lg {
                    font-size: 16px;
                }
            }

            .like {
                color: $gray-700;
                cursor: pointer;

                svg {
                    position: relative;
                    top: 3px;
                    margin-right: 8px;
                }
            }

        }

        h4 {
            color: $black;
        }

        .cta-wrap {
            span {
                text-decoration: none;
                color: $red;
                transition: all 0.5s;
                display: inline-block;
                cursor: pointer;

                &:hover {
                    color: $primary;
                }

                svg {
                    position: relative;
                    top: 4px;
                    margin-left: 8px;
                }
            }
        }
    }
}

.project-modal__content {
    padding: 20px;
    position: relative;

    @include lg {
        max-width: 600px;
    }

    @include xl {
        max-width: 600px;
    }

    @include xxl {
        max-width: 700px;
    }

    .react-responsive-modal-container {
        .react-responsive-modal-closeButton {
            display: none !important;
        }

    }


    .cross-icon {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        outline: none;

        svg {
            fill: $primary;
        }
    }

    .media-screenshot {
        display: inline-block;
        width: 100%;
        margin: 20px 0;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: rgba($primary, 0.6);
        padding: 10px;

        img {
            width: 400px;
            margin: 0 auto;
        }
    }

    .btn-wrap {
        .btn {
            margin: 5px;
        }
    }
}