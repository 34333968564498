//No need for x-tra small since mobile-first will take care of that

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
$screen-xs-max: 575.98px;

// Small tablets (portrait view)
$screen-md-min: 768px;
$screen-sm-max: 767.98px;

// Tablets and small desktops
$screen-lg-min: 992px;
$screen-md-max: 991.98px;

// Large tablets and desktops
$screen-xl-min: 1200px;
$screen-lg-max: 1199.98px;

// Desktop
$screen-xxl-min: 1440px;
$screen-xl-max: 1439.98px;

// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

@mixin xsDown {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

@mixin smDown {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

@mixin mdDown {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}


// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin lgDown {
	@media (max-width: #{$screen-lg-min}) {
		@content;
	}
}

//Desktop
@mixin xxl {
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
}

@mixin xlDown {
	@media (max-width: #{$screen-xl-max}) {
		@content;
	}
}

// Extra large devices
@mixin custom-size($size) {
	@media (min-width: #{$size}) {
		@content;
	}
}

/*
// Usage Example
.container {
    padding: 0 15px;

    // 576px window width and more
    @include sm {
        padding: 0 20px;
    }

    // 992px window width and more
    @include lg {
        margin-left: auto;
        margin-right: auto;
        max-width: 1100px;
    }
}
*/
