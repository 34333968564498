@import "../../../sass/breakpoints";
@import "../../../sass/variables";

.service-card {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba($primary, 0.4) 0px 2px 5px -1px, rgba($primary, 0.8) 0px 1px 3px -1px;
    max-width: 350px;
    margin: 0 auto;
    transition: all 0.4s;
    cursor: pointer;
    height: 100%;

    @include lg {
        padding: 20px 25px;
        max-width: 100%;
    }

    @include xl {
        padding: 35px 25px;
    }

    &__icon {
        padding: 15px;
        width: 55px;
        height: 55px;
        background-color: $primary;
        border: 2px solid $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin-bottom: 10px;

        @include lg {
            width: 70px;
            height: 70px;
            margin-bottom: 15px;
        }

        svg{
            width: 35px;
            height: 35px;
            fill: $white;
        }

    }

    &__content {
        h3 {
            color: $primary;
            margin-bottom: 10px;
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: rgba($primary, 0.5) 0px 10px 20px, rgba($primary, 0.53) 0px 6px 6px;

    }
}