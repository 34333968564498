.site-header {
    // background-color: $primary;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 111;
    background-color: $primary;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 0;
        transition: all 0.3s;

        .menu-icons {
            display: block;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &:focus {
                background: transparent;
            }

            @include lg {
                display: none;
            }

            svg {
                width: 30px;
                height: 30px;
                color: $white;
                stroke-width: 1.5px;
            }
        }
    }

    &__logo {
        display: inline-block;
        text-decoration: none;
        font-weight: 700;
        font-size: 26px;
        color: $white;
        text-transform: uppercase;
        border: 4px solid $white;
        max-width: 220px;
        padding: 5px 15px;


        @include lg {
            font-size: 28px;
            padding: 5px 20px;

        }
    }

    &.sticky {
        position: fixed;
        background-color: $primary;

        .site-header__wrap {
            padding: 0.5rem 0 !important;
        }
    }
}