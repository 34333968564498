h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1rem;
    // text-transform: capitalize;

}

h1 {
    font-size: 28px;
    font-weight: 800;

    @include md {
        font-size: 30px;
    }

    @include lg {
        font-size: 40px;
    }

    @include xl {
        font-size: 50px;
    }

}

h2 {
    font-size: 25px;

    @include md {
        font-size: 28px;
    }

    @include lg {
        font-size: 35px;
    }

}

h3 {
    font-size: 22px;

    @include md {
        font-size: 25px;
    }

    @include xl{
        font-size: 32px;
    }

}

h4 {
    font-size: 22px;

    @include md {
        font-size: 24px;
    }

    @include lg {
        font-size: 26px;
    }


}

p {
    margin-bottom: 10px;
}

p:last-of-type {
    margin-bottom: 0;
}